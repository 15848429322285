/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
// DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as _ from 'lodash';
// COMPONENTS
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
// ICONS
import {
  TbPercentage, TbCurrencyDollar, TbPlus
} from 'react-icons/tb';
// CUSTOM COMPONENTS
import Section from '../../../../components/Section';
import ContentBlock from '../../../../components/ContentBlock';
import CustomBlock from '../../../../components/CustomBlock';
import FormBlock from '../../../../components/FormBlock';
import ContentHeader from '../../../../components/ContentHeader';
import InputBlock from '../../../../components/InputBlock';
import SelectBlock from '../../../../components/SelectBlock';
import Overlay from '../../../../components/Overlay';
import ModalBlock from '../../../../components/ModalBlock';
import FixedActionsBar from '../../../../components/FixedActionsBar';
// HELPERS AND SERVICES
import * as helper from '../../../../helpers/helper';
import * as benchmarkService from '../../../../services/management/benchmarkService';
import * as bidService from '../../../../services/management/bidService';
// REDUX
import * as alert from '../../../../redux/alertToastRedux';
import * as auth from '../../../../redux/authRedux';

const BenchmarkPage = (params) => {
  const { showAlert } = params;
  const { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const initialBenchmark = {
    id: 0, clientId, name: '', min: null, bm: null, chargeType: null
  };
  const [benchmark, setBenchmark] = useState(initialBenchmark);
  const [benchmarks, setBenchmarks] = useState([]);
  const initialGroupModel = {
    id: 0, group: '', benchmarkId: 0, min: '', bm: '', benchmarkName: ''
  };
  const [groupModel, setGroupModel] = useState(initialGroupModel);
  const [isNewBenchmarkModalOpen, setIsNewBenchmarkModalOpen] = useState(false);
  const [type, setType] = useState('ups');

  useEffect(() => {
    fetchBenchmarks();
  }, []);

  const fetchBenchmarks = async () => {
    setIsLoading(true);
    try {
      const res = await benchmarkService.getAll(clientId);

      setFedexTransportationCharges([...fedexTransportationCharges, ...res.filter((x) => x.chargeType === 'Transportation' && x.type === 'fedex').map((x) => ({ ...x, minCharge: x.min, benchmarkDiscount: x.bm }))]);
      setFedexAccessorialCharge([...fedexAccessorialCharge, ...res.filter((x) => x.chargeType === 'Accessorial' && x.type === 'fedex').map((x) => ({ ...x, minCharge: x.min, benchmarkDiscount: x.bm }))]);
      setUpsBMTransportationCharges([...upsBMTransportationCharges, ...res.filter((x) => x.chargeType === 'Transportation' && x.type === 'ups').map((x) => ({ ...x, minCharge: x.min, benchmarkDiscount: x.bm }))]);
      setUpsAccessorialCharges([...upsAccessorialCharges, ...res.filter((x) => x.chargeType === 'Accessorial' && x.type === 'ups').map((x) => ({ ...x, minCharge: x.min, benchmarkDiscount: x.bm }))]);
      setBenchmarks(res);
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const [upsBMTransportationCharges, setUpsBMTransportationCharges] = useState([
    { id: 1, name: '2nd Day Air A.M. Commercial Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 2, name: '2nd Day Air Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 3, name: '2nd Day Air Commercial Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 4, name: '2nd Day Air Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 5, name: '2nd Day Air Residential Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 6, name: '3 Day Select Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 7, name: '3 Day Select Commercial Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 8, name: '3 Day Select Commercial Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 9, name: '3 Day Select Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 10, name: '3 Day Select Residential Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 11, name: '3 Day Select Residential Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 12, name: '3 Day Select Undeliverable Return', minCharge: '', benchmarkDiscount: '' },
    { id: 13, name: 'Commercial Adjustment', minCharge: '', benchmarkDiscount: '' },
    { id: 14, name: 'Ground Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 15, name: 'Ground Commercial Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 16, name: 'Ground Commercial Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 17, name: 'Ground Hundredweight', minCharge: '', benchmarkDiscount: '' },
    { id: 18, name: 'Ground Hundredweight Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 19, name: 'Ground Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 20, name: 'Ground Residential Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 21, name: 'Ground Residential Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 22, name: 'Ground Return to Sender Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 23, name: 'Ground Undeliverable Return', minCharge: '', benchmarkDiscount: '' },
    { id: 24, name: 'GSR Next Day Air Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 25, name: 'Next Day Air Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 26, name: 'Next Day Air Commercial Collect', minCharge: '', benchmarkDiscount: '' },
    { id: 27, name: 'Next Day Air Commercial Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 28, name: 'Next Day Air Early Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 29, name: 'Next Day Air Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 30, name: 'Next Day Air Residential Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 31, name: 'Next Day Air Saver Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 32, name: 'Next Day Air Saver Residential Third Party', minCharge: '', benchmarkDiscount: '' },
    { id: 33, name: 'Not Previously Billed Ground Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 34, name: 'Not Previously Billed Ground Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 35, name: 'Not Previously Billed Next Day Air Commercial', minCharge: '', benchmarkDiscount: '' },
    { id: 36, name: 'Not Previously Billed Next Day Air Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 37, name: 'Residential Adjustment', minCharge: '', benchmarkDiscount: '' },
    { id: 38, name: 'Shipping Charge Correction', minCharge: '', benchmarkDiscount: '' },
    { id: 39, name: 'Shipping Charge Correction 2nd Day Air', minCharge: '', benchmarkDiscount: '' },
    { id: 40, name: 'Shipping Charge Correction 2nd Day Air A.M.', minCharge: '', benchmarkDiscount: '' },
    { id: 41, name: 'Shipping Charge Correction 3 Day Select', minCharge: '', benchmarkDiscount: '' },
    { id: 42, name: 'Shipping Charge Correction 3 Day Select Undeliverable Return', minCharge: '', benchmarkDiscount: '' },
    { id: 43, name: 'Shipping Charge Correction Ground', minCharge: '', benchmarkDiscount: '' },
    { id: 44, name: 'Shipping Charge Correction Next Day Air', minCharge: '', benchmarkDiscount: '' },
    { id: 45, name: 'Shipping Charge Correction Next Day Air Early', minCharge: '', benchmarkDiscount: '' },
    { id: 46, name: 'Shipping Charge Correction Next Day Air Saver', minCharge: '', benchmarkDiscount: '' },
    { id: 47, name: 'Worldwide Expedited', minCharge: '', benchmarkDiscount: '' },
    { id: 48, name: 'WW Saver', minCharge: '', benchmarkDiscount: '' }
  ]);

  const [upsAccessorialCharges, setUpsAccessorialCharges] = useState([
    { id: 1, name: 'Additional Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 2, name: 'Additional Handling - Length+Girth', minCharge: '', benchmarkDiscount: '' },
    { id: 3, name: 'Additional Handling Charge - Package', minCharge: '', benchmarkDiscount: '' },
    { id: 4, name: 'Addl. Handling longest side', minCharge: '', benchmarkDiscount: '' },
    { id: 5, name: 'Addl. Handling weight', minCharge: '', benchmarkDiscount: '' },
    { id: 6, name: 'Address Correction Ground', minCharge: '', benchmarkDiscount: '' },
    { id: 7, name: 'Declared Value', minCharge: '', benchmarkDiscount: '' },
    { id: 8, name: 'Delivery Area Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 9, name: 'Delivery Area Surcharge - Extended', minCharge: '', benchmarkDiscount: '' },
    { id: 10, name: 'Delivery Area Surcharge - Extended Adjustment', minCharge: '', benchmarkDiscount: '' },
    { id: 11, name: 'Demand Surcharge-Addl Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 12, name: 'Demand Surcharge-Com', minCharge: '', benchmarkDiscount: '' },
    { id: 13, name: 'Dimensional', minCharge: '', benchmarkDiscount: '' },
    { id: 14, name: 'Disbursement Fee', minCharge: '', benchmarkDiscount: '' },
    { id: 15, name: 'Early Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 16, name: 'Fuel Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 17, name: 'Fuel Surcharge Adjustment', minCharge: '', benchmarkDiscount: '' },
    { id: 18, name: 'Future Day Pickup - Alternate Address - Web Request', minCharge: '', benchmarkDiscount: '' },
    { id: 19, name: 'Future Day Pickup - Web Request', minCharge: '', benchmarkDiscount: '' },
    { id: 20, name: 'HazMat Ground Fully Reg', minCharge: '', benchmarkDiscount: '' },
    { id: 21, name: 'Late Payment Fee', minCharge: '', benchmarkDiscount: '' },
    { id: 22, name: 'Not Previously Billed Additional Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 23, name: 'Not Previously Billed Delivery Area Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 24, name: 'Not Previously Billed Delivery Area Surcharge - Extended', minCharge: '', benchmarkDiscount: '' },
    { id: 25, name: 'Not Previously Billed Demand Surcharge-Addl Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 26, name: 'Not Previously Billed Fuel Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 27, name: 'Not Previously Billed Missing PLD Fee', minCharge: '', benchmarkDiscount: '' },
    { id: 28, name: 'Not Previously Billed Remote Area Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 29, name: 'Not Previously Billed Residential Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 30, name: 'Print Return Label', minCharge: '', benchmarkDiscount: '' },
    { id: 31, name: 'Remote Area Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 32, name: 'Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 33, name: 'Residential Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 34, name: 'Residential Surcharge Adjustment', minCharge: '', benchmarkDiscount: '' },
    { id: 35, name: 'Same Day Pickup - Alternate Address - Web Request', minCharge: '', benchmarkDiscount: '' },
    { id: 36, name: 'Same Day Pickup - Web Request', minCharge: '', benchmarkDiscount: '' },
    { id: 37, name: 'Service Charge', minCharge: '', benchmarkDiscount: '' },
    { id: 38, name: 'Shipping Charge Correction Additional Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 39, name: 'Shipping Charge Correction Addl. Handling longest side', minCharge: '', benchmarkDiscount: '' },
    { id: 40, name: 'Shipping Charge Correction Demand Surcharge-Addl Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 41, name: 'Shipping Charge Correction Fuel Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 42, name: 'Third Party Billing Service', minCharge: '', benchmarkDiscount: '' },
    { id: 43, name: 'UPS carbon neutral', minCharge: '', benchmarkDiscount: '' },
    { id: 44, name: 'Weekly Service Chg', minCharge: '', benchmarkDiscount: '' }
  ]);

  const [fedexAccessorialCharge, setFedexAccessorialCharge] = useState([
    { id: 1, name: 'Fuel Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 2, name: 'AHS - Dimensions', minCharge: '', benchmarkDiscount: '' },
    { id: 3, name: 'AHS - Weight', minCharge: '', benchmarkDiscount: '' },
    { id: 4, name: 'Courier Pickup Charge', minCharge: '', benchmarkDiscount: '' },
    { id: 5, name: 'DAS Comm', minCharge: '', benchmarkDiscount: '' },
    { id: 6, name: 'DAS Extended Comm', minCharge: '', benchmarkDiscount: '' },
    { id: 7, name: 'DAS Remote Resi', minCharge: '', benchmarkDiscount: '' },
    { id: 8, name: 'DAS Resi', minCharge: '', benchmarkDiscount: '' },
    { id: 9, name: 'Residential', minCharge: '', benchmarkDiscount: '' },
    { id: 10, name: 'Earned Discount', minCharge: '', benchmarkDiscount: '' },
    { id: 11, name: 'Discount', minCharge: '', benchmarkDiscount: '' },
    { id: 12, name: 'Address Correction', minCharge: '', benchmarkDiscount: '' },
    { id: 13, name: 'Direct Signature', minCharge: '', benchmarkDiscount: '' },
    { id: 14, name: 'Peak - AHS Charge', minCharge: '', benchmarkDiscount: '' },
    { id: 15, name: 'Peak Surcharge', minCharge: '', benchmarkDiscount: '' },
    { id: 16, name: 'Performance Pricing', minCharge: '', benchmarkDiscount: '' },
    { id: 17, name: 'Saturday Delivery', minCharge: '', benchmarkDiscount: '' },
    { id: 18, name: 'Additional Handling', minCharge: '', benchmarkDiscount: '' },
    { id: 19, name: 'Oversize Charge', minCharge: '', benchmarkDiscount: '' },
    { id: 20, name: 'Tracking ID Charge Description', minCharge: '', benchmarkDiscount: '' },
    { id: 21, name: 'Additional Handling Charge - Package', minCharge: '', benchmarkDiscount: '' },
    { id: 22, name: 'Disbursement Fee', minCharge: '', benchmarkDiscount: '' },
    { id: 23, name: 'Merchandise Processing Fee', minCharge: '', benchmarkDiscount: '' },
    { id: 24, name: 'Original VAT', minCharge: '', benchmarkDiscount: '' },
    { id: 25, name: 'Customs Duty', minCharge: '', benchmarkDiscount: '' },
    { id: 26, name: 'Weekly Service Chg', minCharge: '', benchmarkDiscount: '' },
    { id: 27, name: 'Additional Handling Charge - Weight', minCharge: '', benchmarkDiscount: '' }
  ]);

  const [fedexTransportationCharges, setFedexTransportationCharges] = useState([
    { id: 1, name: 'FedEx 2Day', minCharge: '', benchmarkDiscount: '' },
    { id: 2, name: 'FedEx 2Day A.M.', minCharge: '', benchmarkDiscount: '' },
    { id: 3, name: 'FedEx 2Day Freight', minCharge: '', benchmarkDiscount: '' },
    { id: 4, name: 'FedEx Express Saver', minCharge: '', benchmarkDiscount: '' },
    { id: 5, name: 'FedEx FedEx Intl Connect Plus', minCharge: '', benchmarkDiscount: '' },
    { id: 6, name: 'FedEx First Overnight', minCharge: '', benchmarkDiscount: '' },
    { id: 7, name: 'FedEx International Priority', minCharge: '', benchmarkDiscount: '' },
    { id: 8, name: 'FedEx Intl Economy', minCharge: '', benchmarkDiscount: '' },
    { id: 9, name: 'FedEx Priority Overnight', minCharge: '', benchmarkDiscount: '' },
    { id: 10, name: 'FedEx Standard Overnight', minCharge: '', benchmarkDiscount: '' },
    { id: 11, name: 'Ground', minCharge: '', benchmarkDiscount: '' },
    { id: 12, name: 'Home Delivery', minCharge: '', benchmarkDiscount: '' },
    { id: 13, name: 'FedEx Intl Priority Express', minCharge: '', benchmarkDiscount: '' }
  ]);

  const addBenchmark = (shouldCloseModal) => {
    if (benchmark.name && benchmark.chargeType) {
      setBenchmarks([...benchmarks, { ...benchmark, chargeType: benchmark.chargeType.value }]);
      const benchmarkToAdd = { ...benchmark, minCharge: benchmark.minCharge, benchmarkDiscount: benchmark.bm, chargeType: benchmark.chargeType.value };

      const chargeTypeMap = {
        fedex: {
          Accessorial: setFedexAccessorialCharge,
          Transportation: setFedexTransportationCharges,
        },
        ups: {
          Accessorial: setUpsAccessorialCharges,
          Transportation: setUpsBMTransportationCharges,
        },
      };
      if (chargeTypeMap[benchmarkToAdd.type]?.[benchmarkToAdd.chargeType]) {
        chargeTypeMap[benchmarkToAdd.type][benchmarkToAdd.chargeType]((prevCharges) => [...prevCharges, benchmarkToAdd]);
      }

      setBenchmark(initialBenchmark);
      if (shouldCloseModal) {
        setIsNewBenchmarkModalOpen(false);
      }
    }
  };

  const handleValueUpdate = (name, key, value) => {
    const findBenchmark = benchmarks.find((x) => x.name === name);
    if (findBenchmark) {
      findBenchmark[key] = value;
      setBenchmarks([...benchmarks]);
    } else {
      setBenchmarks([...benchmarks, { id: 0, clientId, name, [key]: value }]);
    }
  };

  const getValueByName = (name, key) => {
    const findBenchmark = benchmarks.find((x) => x.name === name);
    if (findBenchmark) {
      return findBenchmark[key];
    }

    return '';
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);
    try {
      const res = await benchmarkService.saveChanges(benchmarks);
      showAlert({ text: res.message, state: 'success' });
    } catch (ex) {
      showAlert({ text: ex.message, state: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const addGroup = (shouldCloseModal) => {
    const findBenchmark = benchmarks.find((x) => x.name === groupModel.benchmarkName);
    if (findBenchmark) {
      findBenchmark.benchmarkGroups.push(groupModel);
      setBenchmarks([...benchmarks]);
    } else {
      setBenchmarks([...benchmarks, { id: 0, clientId, name: groupModel.benchmarkName, benchmarkGroups: [groupModel] }]);
    }

    setGroupModel({ ...initialGroupModel, benchmarkName: groupModel.benchmarkName, benchmarkId: groupModel.benchmarkId });

    if (shouldCloseModal) {
      setIsModalVisible(false);
    }
  };

  const handleInLineChange = (name, order, key, value) => {
    const findBenchmark = benchmarks.find((x) => x.name === name);
    if (findBenchmark) {
      findBenchmark.benchmarkGroups[order][key] = value;
      setBenchmarks([...benchmarks]);
    }
  };

  return (
    <>
      {isLoading && <Overlay hasLoader />}
      <CustomBlock className="content-container--padded mb-200">
        <Section isFullWidth>
          <ContentHeader
            title="Benchmark"
            headerSize="lg"
            primaryButtonText="New Benchmark"
            primaryButtonOnClick={() => setIsNewBenchmarkModalOpen(true)}
          />
          <ContentBlock>
            <Tabs
              activeKey={type}
              onSelect={(k) => setType(k)}
              id="benchmark-tab"
              className="mb-3"
            >
              <Tab
                eventKey="ups"
                title={(
                  <ContentHeader
                    title="UPS"
                    headerSize="xs"
                    className="black--clr"
                    containerClassName="pb-0"
                  />
                )}
              >
                <FormBlock>
                  <Section hasNoContainer>
                    <ContentBlock cols={6}>
                      <Section hasNoContainer>
                        <ContentBlock>
                          <ContentHeader
                            title="Transportation Charges"
                            headerSize="sm"
                            containerClassName="pb-0"
                            className="pb-0 pt-20"
                          />
                        </ContentBlock>
                        {upsBMTransportationCharges.map((item) => (
                          <ContentBlock key={item.id}>
                            <Section hasNoContainer>
                              <ContentBlock cols={8}>
                                <InputBlock
                                  value={item.name}
                                  inputState="disabled"
                                  containerClassName="minimal mr-10"
                                  containerStyle={{ marginTop: 23 }}
                                  inputRightButtonText="Group"
                                  inputRightButtonBackgroundColor="success--bg white--clr"
                                  inputRightButtonIconLeft={<TbPlus className="white--clr" size={15} />}
                                  inputRightButtonOnClick={() => {
                                    setGroupModel({ ...initialGroupModel, benchmarkName: item.name, benchmarkId: item.id });
                                    setIsModalVisible(true);
                                  }}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="Min"
                                  // value={item.minCharge}
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbCurrencyDollar
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'min', target.value)}
                                  value={getValueByName(item.name, 'min')}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="BM"
                                  // value={item.benchmarkDiscount}
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbPercentage
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'bm', target.value)}
                                  value={getValueByName(item.name, 'bm')}
                                />
                              </ContentBlock>
                            </Section>
                            {benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.length > 0 && (
                              <ContentBlock cols={6} className="ml-15 mt-10">
                                <CustomBlock className="light-grey--brd p-10 pt-10 radius--rounder">
                                  <p className="dark--clr size-xxxs fw-600">{item.name}</p>
                                  {
                                    benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.map((groupItem, index) => (
                                      <Section hasNoContainer className="p-0 mb-10">
                                        <ContentBlock cols={6}>
                                          <InputBlock
                                            inputLabel="Group"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.group}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'group', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="Min"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.min}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'min', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="BM"
                                            containerClassName="minimal"
                                            value={groupItem.bm}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'bm', target.value)}
                                          />
                                        </ContentBlock>
                                      </Section>
                                    ))
                                  }
                                </CustomBlock>
                              </ContentBlock>
                            )}
                          </ContentBlock>
                        ))}
                      </Section>
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <Section hasNoContainer>
                        <ContentBlock>
                          <ContentHeader
                            title="Accessorial Charges"
                            headerSize="sm"
                            containerClassName="pb-0"
                            className="pb-0 pt-20"
                          />
                        </ContentBlock>

                        {upsAccessorialCharges.map((item) => (
                          <ContentBlock key={item.id}>
                            <Section hasNoContainer>
                              <ContentBlock cols={8}>
                                <InputBlock
                                  value={item.name}
                                  inputState="disabled"
                                  containerClassName="minimal mr-10"
                                  containerStyle={{ marginTop: 23 }}
                                  inputRightButtonText="Group"
                                  inputRightButtonBackgroundColor="success--bg white--clr"
                                  inputRightButtonIconLeft={<TbPlus className="white--clr" size={15} />}
                                  inputRightButtonOnClick={() => {
                                    setGroupModel({ ...initialGroupModel, benchmarkName: item.name, benchmarkId: item.id });
                                    setIsModalVisible(true);
                                  }}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="Min"
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbCurrencyDollar
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'min', target.value)}
                                  value={getValueByName(item.name, 'min')}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="BM"
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbPercentage
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'bm', target.value)}
                                  value={getValueByName(item.name, 'bm')}
                                />
                              </ContentBlock>
                            </Section>
                            {benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.length > 0 && (
                              <ContentBlock cols={6} className="ml-15 mt-10">
                                <CustomBlock className="light-grey--brd p-10 pt-10 radius--rounder">
                                  <p className="dark--clr size-xxxs fw-600">{item.name}</p>
                                  {
                                    benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.map((groupItem, index) => (
                                      <Section hasNoContainer className="p-0 mb-10">
                                        <ContentBlock cols={6}>
                                          <InputBlock
                                            inputLabel="Group"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.group}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'group', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="Min"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.min}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'min', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="BM"
                                            containerClassName="minimal"
                                            value={groupItem.bm}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'bm', target.value)}
                                          />
                                        </ContentBlock>
                                      </Section>
                                    ))
                                  }
                                </CustomBlock>
                              </ContentBlock>
                            )}
                          </ContentBlock>
                        ))}
                      </Section>
                    </ContentBlock>
                  </Section>
                </FormBlock>
              </Tab>

              <Tab
                eventKey="fedex"
                title={(
                  <ContentHeader
                    title="FedEx"
                    headerSize="xs"
                    className="dark--clr"
                    containerClassName="pb-0"
                  />
                )}
              >
                <FormBlock>
                  <Section hasNoContainer>
                    <ContentBlock cols={6}>
                      <Section hasNoContainer>
                        <ContentBlock>
                          <ContentHeader
                            title="Transportation Charges"
                            headerSize="sm"
                            containerClassName="pb-0"
                            className="pb-0 pt-20"
                          />
                        </ContentBlock>
                        {fedexTransportationCharges.map((item) => (
                          <ContentBlock key={item.id}>
                            <Section hasNoContainer>
                              <ContentBlock cols={8}>
                                <InputBlock
                                  value={item.name}
                                  inputState="disabled"
                                  containerClassName="minimal mr-10"
                                  containerStyle={{ marginTop: 23 }}
                                  inputRightButtonText="Group"
                                  inputRightButtonBackgroundColor="success--bg white--clr"
                                  inputRightButtonIconLeft={<TbPlus className="white--clr" size={15} />}
                                  inputRightButtonOnClick={() => {
                                    setGroupModel({ ...initialGroupModel, benchmarkName: item.name, benchmarkId: item.id });
                                    setIsModalVisible(true);
                                  }}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="Min"
                                  // value={item.minCharge}
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbCurrencyDollar
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'min', target.value)}
                                  value={getValueByName(item.name, 'min')}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="BM"
                                  // value={item.benchmarkDiscount}
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbPercentage
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'bm', target.value)}
                                  value={getValueByName(item.name, 'bm')}
                                />
                              </ContentBlock>
                            </Section>
                            {benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.length > 0 && (
                              <ContentBlock cols={6} className="ml-15 mt-10">
                                <CustomBlock className="light-grey--brd p-10 pt-10 radius--rounder">
                                  <p className="dark--clr size-xxxs fw-600">{item.name}</p>
                                  {
                                    benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.map((groupItem, index) => (
                                      <Section hasNoContainer className="p-0 mb-10">
                                        <ContentBlock cols={6}>
                                          <InputBlock
                                            inputLabel="Group"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.group}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'group', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="Min"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.min}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'min', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="BM"
                                            containerClassName="minimal"
                                            value={groupItem.bm}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'bm', target.value)}
                                          />
                                        </ContentBlock>
                                      </Section>
                                    ))
                                  }
                                </CustomBlock>
                              </ContentBlock>
                            )}
                          </ContentBlock>
                        ))}
                      </Section>
                    </ContentBlock>

                    <ContentBlock cols={6}>
                      <Section hasNoContainer>
                        <ContentBlock>
                          <ContentHeader
                            title="Accessorial Charges"
                            headerSize="sm"
                            containerClassName="pb-0"
                            className="pb-0 pt-20"
                          />
                        </ContentBlock>

                        {fedexAccessorialCharge.map((item) => (
                          <ContentBlock key={item.id}>
                            <Section hasNoContainer>
                              <ContentBlock cols={8}>
                                <InputBlock
                                  value={item.name}
                                  inputState="disabled"
                                  containerClassName="minimal mr-10"
                                  containerStyle={{ marginTop: 23 }}
                                  inputRightButtonText="Group"
                                  inputRightButtonBackgroundColor="success--bg white--clr"
                                  inputRightButtonIconLeft={<TbPlus className="white--clr" size={15} />}
                                  inputRightButtonOnClick={() => {
                                    setGroupModel({ ...initialGroupModel, benchmarkName: item.name, benchmarkId: item.id });
                                    setIsModalVisible(true);
                                  }}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="Min"
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbCurrencyDollar
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'min', target.value)}
                                  value={getValueByName(item.name, 'min')}
                                />
                              </ContentBlock>

                              <ContentBlock cols={2}>
                                <InputBlock
                                  inputLabel="BM"
                                  containerClassName="minimal mr-10"
                                  iconRight={(
                                    <TbPercentage
                                      className="grey--clr" size={20}
                                      style={{ marginTop: 50 }}
                                    />
                                  )}
                                  onChange={({ target }) => handleValueUpdate(item.name, 'bm', target.value)}
                                  value={getValueByName(item.name, 'bm')}
                                />
                              </ContentBlock>
                            </Section>
                            {benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.length > 0 && (
                              <ContentBlock cols={6} className="ml-15 mt-10">
                                <CustomBlock className="light-grey--brd p-10 pt-10 radius--rounder">
                                  <p className="dark--clr size-xxxs fw-600">{item.name}</p>
                                  {
                                    benchmarks.find((x) => x.name === item.name)?.benchmarkGroups?.map((groupItem, index) => (
                                      <Section hasNoContainer className="p-0 mb-10">
                                        <ContentBlock cols={6}>
                                          <InputBlock
                                            inputLabel="Group"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.group}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'group', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="Min"
                                            containerClassName="minimal mr-10"
                                            value={groupItem.min}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'min', target.value)}
                                          />
                                        </ContentBlock>

                                        <ContentBlock cols={3}>
                                          <InputBlock
                                            inputLabel="BM"
                                            containerClassName="minimal"
                                            value={groupItem.bm}
                                            onChange={({ target }) => handleInLineChange(item.name, index, 'bm', target.value)}
                                          />
                                        </ContentBlock>
                                      </Section>
                                    ))
                                  }
                                </CustomBlock>
                              </ContentBlock>
                            )}
                          </ContentBlock>
                        ))}
                      </Section>
                    </ContentBlock>
                  </Section>
                </FormBlock>
              </Tab>
            </Tabs>
          </ContentBlock>
        </Section>
      </CustomBlock>

      <FixedActionsBar
        primaryActionText="Save Changes"
        secondaryActionText="Cancel"
        primaryActionOnClick={handleSaveChanges}
      />

      <ModalBlock
        size="lg"
        contentHeader="Group Details"
        subtitle={groupModel.benchmarkName}
        containerClassName="pb-15"
        subtitleClassName="pb-0 pt-5"
        isVisible={isModalVisible}
        primaryModalActionText="Save & Add New"
        primaryModalActionOnClick={() => addGroup(false)}
        thirdModalActionText="Save & Close"
        thirdModalActionOnClick={() => addGroup(true)}
        onHide={() => {
          setIsModalVisible(false);
        }}
      >
        <FormBlock>
          <Section hasNoContainer>
            <ContentBlock cols={8}>
              <InputBlock
                label="Group Name"
                value={groupModel.group}
                onChange={({ target }) => setGroupModel({ ...groupModel, group: target.value })}
              />
            </ContentBlock>
            <ContentBlock cols={2}>
              <InputBlock
                label="Min"
                value={groupModel.min}
                onChange={({ target }) => setGroupModel({ ...groupModel, min: target.value })}
              />
            </ContentBlock>
            <ContentBlock cols={2}>
              <InputBlock
                label="BM"
                value={groupModel.bm}
                onChange={({ target }) => setGroupModel({ ...groupModel, bm: target.value })}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>

      <ModalBlock
        size="lg"
        contentHeader="New Benchmark"
        containerClassName="pb-15"
        subtitleClassName="pb-0 pt-5"
        isVisible={isNewBenchmarkModalOpen}
        primaryModalActionText="Save & Add New"
        primaryModalActionOnClick={() => addBenchmark(false)}
        thirdModalActionText="Save & Close"
        thirdModalActionOnClick={() => addBenchmark(true)}
        onHide={() => {
          setBenchmark(initialBenchmark);
          setIsNewBenchmarkModalOpen(false);
        }}
      >
        <FormBlock>
          <Section hasNoContainer>

            <ContentBlock cols={12}>
              <SelectBlock
                label="Charge Type"
                options={['Accessorial', 'Transportation'].map((x) => ({ label: x, value: x }))}
                isClearable
                value={benchmark.chargeType}
                onChange={(opt) => {
                  opt = opt === null ? [] : opt;
                  setBenchmark({ ...benchmark, chargeType: opt, type });
                }}
              />
            </ContentBlock>
            <ContentBlock cols={8}>
              <InputBlock
                label="Label"
                value={benchmark.name}
                onChange={({ target }) => setBenchmark({ ...benchmark, name: target.value, type })}
              />
            </ContentBlock>
            <ContentBlock cols={2}>
              <InputBlock
                label="Min"
                value={benchmark.min}
                onChange={({ target }) => setBenchmark({ ...benchmark, min: target.value, type })}
              />
            </ContentBlock>
            <ContentBlock cols={2}>
              <InputBlock
                label="BM"
                value={benchmark.bm}
                onChange={({ target }) => setBenchmark({ ...benchmark, bm: target.value, type })}
              />
            </ContentBlock>
          </Section>
        </FormBlock>
      </ModalBlock>
    </>
  );
};

const mapStateFromProps = (state) => ({ auth: state.auth });

export default connect(
  mapStateFromProps,
  {
    ...auth.actions,
    ...alert.actions
  }
)(BenchmarkPage);